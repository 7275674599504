// const BASE_URL = "http://localhost:4000/backend-svc";
const BASE_URL = "https://qa-admin.wanderinglama.com/backend-svc";
// const BASE_URL = "https://admin.wanderinglama.com/backend-svc";
// const BASE_URL = "https://host.docker.internal:4000/backend-svc";
export const AUTH_URL = BASE_URL + "/api/v1/auth";
export const PROPERTY_URL = BASE_URL + "/api/v1/property";
export const ENQUIRY_URL = BASE_URL + "/api/v1/enquiry";
export const LEAD_URL = BASE_URL + "/api/v1/lead";
export const PAYMENT_URL = BASE_URL + "/api/v1/payment";
export const LOCATION_SUGGESTION_URL = BASE_URL + "/api/v1/loaction-suggestion";
export const PRODUCTS_URL = BASE_URL + "/api/v1/products";
export const BOOKING_URL = BASE_URL + "/api/v1/booking";
