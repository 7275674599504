import Slider from "@material-ui/core/Slider";
import { StylesProvider } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Shimmer } from "react-shimmer";
import { WIDTH_LIMIT } from "../../../../data/constants/Constants";
import { ApiRequestGet } from "../../../../data/network/services/ApiRequestGet";
import {
  updateCheckInCheckOut,
  updateDestination,
  updatePropertyType,
} from "../../../../data/redux/action/searchAction";
import ResizeListener from "../../../../utility/ResizeListener";
import secureStorage from "../../../../utility/secureStorage";
import { CustomButton } from "../../../components/customButton/CustomButton";
import { CustomInput } from "../../../components/customInput/CustomInput";
import SelectLabel from "../../../components/SelectLabel";
import Enquiry from "../../../modals/Enquiry";
import {
  Edit,
  EventTabIcon,
  ExperienceTabIcon,
  FilterIcon,
  LocationIcon,
  SearchIcon,
  StayTabIcon,
  TourTabIcon,
} from "../../../resources/assetsManager";
import { SIDEBAR_COLOR, WHITE_COLOR } from "../../../resources/colorsManager";
import {
  EVENTS,
  EXPERIENCES,
  MAX_PRICE_RANGE,
  STAYS,
  TOURS,
} from "../../../resources/constantsManager";
import {
  SS_ACTIVE_TAB,
  SS_CHECK_IN_CHECK_OUT,
  SS_DESTINATION,
  SS_PRICE_RANGE,
  SS_PROPERTY_TYPE,
} from "../../../resources/secureStorageManager";
import "./property.css";
import PropertyItem from "./PropertyItem";
import { useLocation, useNavigate } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import {
  eventsSubCategory,
  experienceSubCategory,
  toursSubCategory,
} from "../../../../utility/Constants";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PropretyList = ({
  destinationRed,
  checkInCheckOutRed,
  roomGuestRed,
  propertyTypeRed,
}) => {
  const { height, width } = ResizeListener();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [priceRange, setPriceRange] = useState(MAX_PRICE_RANGE);
  const [destination, setDestination] = useState("");
  const [checkInCheckOut, setCheckInCheckOut] = useState("");
  const [showLoactionSuggestion, setShowLoactionSuggestion] = useState("");
  const [locationArr, setLocationArr] = useState([]);
  // const [roomGuest, setRoomGuest] = useState("");
  const [propertyType, setPropertyType] = useState("none");
  const [enquiryOpen, setEnquiryOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [activeEnquiryProperty, setActiveEnquiryProperty] = useState({});
  const [searchLocation, setSearchLocation] = useState("");
  const [activeTab, setActiveTab] = useState(STAYS);
  const [showActiveType, setShowActiveType] = useState(STAYS);
  const [activeSubCategory, setActiveSubCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);

  const mainCategoryStyles = {
    display: "flex",
    width: "45%",
    float: "left",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "40px",
    padding: 10,
    border: "1px solid #E2E2E2",
    cursor: "pointer",
    margin: 5,
  };

  const propTypeOptions = [
    { key: 0, value: "none", label: "Property Type" },
    { key: 1, value: "RESORT", label: "Resort" },
    { key: 2, value: "HOMESTAY", label: "Homestay" },
    { key: 3, value: "ACTIVITY", label: "Activity" },
  ];

  // Changing State when volume increases/decreases
  const rangeSelector = (event, newValue) => {
    setPriceRange(newValue);
  };

  useEffect(() => {
    let pathname = window.location.pathname.split("/");
    let locationQuery = pathname[pathname.length - 4];
    locationQuery = decodeURI(locationQuery);
    setDestination(locationQuery);
    setSearchLocation(locationQuery);
    let typeQuery = pathname[pathname.length - 3];
    let subTypeQuery = pathname[pathname.length - 2];
    const priceRangeQuery = pathname[pathname.length - 1];
    setPriceRange(parseInt(priceRangeQuery));
    typeQuery = typeQuery.toUpperCase();
    subTypeQuery = subTypeQuery.toUpperCase();
    setActiveTab(typeQuery ? typeQuery.toUpperCase() : STAYS);
    secureStorage.setItem(SS_ACTIVE_TAB, typeQuery);
    let activeTabfn = "";

    if (typeQuery === STAYS) {
      setSubCategory([]);
      activeTabfn = typeQuery;
    }
    if (typeQuery === EXPERIENCES) {
      setSubCategory(experienceSubCategory);
      setActiveSubCategory(subTypeQuery);
      activeTabfn = subTypeQuery;
    }
    if (typeQuery === EVENTS) {
      setSubCategory(eventsSubCategory);
      setActiveSubCategory(subTypeQuery);
      activeTabfn = subTypeQuery;
    }
    if (typeQuery === TOURS) {
      setSubCategory(toursSubCategory);
      setActiveSubCategory(subTypeQuery);
      activeTabfn = subTypeQuery;
    }

    if (locationQuery) {
      getPropertyByFilter(locationQuery, priceRangeQuery, activeTabfn);
    }
  }, []);

  const setExperienceTabFilter = async () => {
    setActiveTab(EXPERIENCES);
    setSubCategory(experienceSubCategory);
    setActiveSubCategory(experienceSubCategory[0].value);
    await secureStorage.setItem(SS_ACTIVE_TAB, EXPERIENCES);
  };

  const getPropertyByFilter = (location = "", priceRange, type) => {
    setLoading(true);
    ApiRequestGet.getPropertyByFilter(
      location,
      priceRange ? priceRange : MAX_PRICE_RANGE,
      type
    )
      .then((res) => {
        if (res?.data?.success) {
          setShowActiveType(
            type === STAYS ? type.toLowerCase() : type.toLowerCase() + "s"
          );
          setData(res?.data?.data?.products);
        } else
          enqueueSnackbar(
            "Something went worng, while getting property list ",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
      })
      .catch((err) => {
        console.log("getAllProperty error", err);
      })
      .finally(() => setLoading(false));
  };

  const onSearch = async () => {
    setOpenFilter(false);
    dispatch(updateDestination(destination));
    dispatch(updateCheckInCheckOut(checkInCheckOut));
    dispatch(updatePropertyType(propertyType));
    // dispatch(updateRoomGuest(roomGuest));
    await secureStorage.setItem(SS_DESTINATION, destination);
    await secureStorage.setItem(SS_CHECK_IN_CHECK_OUT, checkInCheckOut);
    await secureStorage.setItem(SS_PROPERTY_TYPE, propertyType);
    // await secureStorage.setItem(SS_ROOM_GUEST, roomGuest);
    getPropertyByFilter(
      destination,
      priceRange,
      activeTab === STAYS ? activeTab : activeSubCategory
    );
    setSearchLocation(destination);
  };

  const getLocationSuggestions = (search) => {
    // setLoading(true);
    ApiRequestGet.getLocationSuggestions(search)
      .then((res) => {
        setLocationArr(res?.data?.data);
        console.log(res?.data);
        // setOpenFilter(false);
        setShowLoactionSuggestion(true);
      })
      .catch((err) => {
        console.log("getLocationSuggestions error", err);
      });
    // .finally(() => setLoading(false));
  };

  const onApplyFilter = async () => {
    dispatch(updateDestination(destination));
    await secureStorage.setItem(SS_DESTINATION, destination);
    setOpenFilter(false);
    await secureStorage.setItem(SS_PRICE_RANGE, priceRange);
    const subType = activeTab === STAYS ? "none" : activeSubCategory;
    navigate(
      `/property-list/${destination}/${activeTab.toLowerCase()}/${subType.toLowerCase()}/${priceRange}`
    );
    getPropertyByFilter(
      destination,
      priceRange,
      activeTab === STAYS ? activeTab : activeSubCategory
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // if (destinationRed) setDestination(destinationRed);
    if (checkInCheckOutRed) setCheckInCheckOut(checkInCheckOutRed);
    if (propertyTypeRed) setPropertyType(propertyTypeRed);

    // if (roomGuestRed) setRoomGuest(roomGuestRed);
    // setSearchLocation(destinationRed);
  }, [destinationRed, checkInCheckOutRed, roomGuestRed, propertyTypeRed]);

  const SubCategory = ({ title, value }) => {
    return (
      <div
        onClick={() => {
          setActiveSubCategory(value);
        }}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "50%",
          float: "left",
          cursor: "pointer",
        }}
      >
        <Checkbox
          checked={activeSubCategory === value}
          // onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            color: "#000000",
            "&.Mui-checked": {
              color: "#000000",
              paddingLeft: 0,
            },
            "&.MuiCheckbox-root": {
              paddingLeft: 0,
            },
            marginLeft: 0,
          }}
        />
        <p style={{ margin: 0, fontSize: 15 }}>{title}</p>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: width < WIDTH_LIMIT ? "column" : "row",
        width: "100%",
        padding: width > WIDTH_LIMIT ? 25 : 10,
      }}
    >
      {width < WIDTH_LIMIT ? (
        <></>
      ) : (
        <>
          <div
            style={{
              width: "25%",
              position: "sticky",
              top: 100,
              height: height * 0.8,
              padding: 20,
              border: "1px solid #D9D9D9",
              borderRadius: "5px",
            }}
          >
            <div style={{ paddingBottom: 20 }}>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "600",
                  color: "#000",
                  margin: "0px 0px 20px 0px",
                }}
              >
                Search your location
              </p>
              <div style={{ position: "relative" }}>
                <CustomInput
                  type="input"
                  placeholder="Search by City, State"
                  onChange={(val) => {
                    setDestination(val);
                    setShowLoactionSuggestion(true);
                    getLocationSuggestions(val);
                  }}
                  value={destination}
                  inputcomponentStyles={{
                    width: "87%",
                    fontWeight: destination ? "500" : "100",
                  }}
                  inputStyles={{
                    boxShadow: "none",
                    height: "40px",
                    backgroundColor: "white",
                    border: "1px solid #101010",
                  }}
                  isRightIconRequired={true}
                  rightIcon={
                    <img
                      style={{ width: 15, cursor: "pointer" }}
                      src={LocationIcon}
                    />
                  }
                />

                {showLoactionSuggestion && (
                  <div
                    style={{
                      position: "absolute",
                      top: 72,
                      width: "100%",
                      borderRadius: 5,
                      backgroundColor: "#fff",
                      maxHeight: 250,
                      overflow: "scroll",
                      zIndex: 10,
                      border: "1px solid #aaa",
                    }}
                  >
                    {locationArr.map((item, index) => (
                      <p
                        key={index}
                        onClick={() => {
                          setDestination(item.value);
                          setShowLoactionSuggestion(false);
                        }}
                        style={{
                          borderBottom: "1px solid #ccc",
                          margin: 5,
                          padding: 10,
                          fontSize: 16,
                        }}
                      >
                        {item.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div style={{ marginTop: 30 }}>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: "600",
                    color: "#000",
                    margin: "10px 0px",
                  }}
                >
                  Main categories
                </p>

                <div
                  style={{
                    // display: "table",
                    display: "flex",
                    justifyContent: "space-between",
                    clear: "both",
                  }}
                >
                  <div
                    onClick={async () => {
                      setActiveTab(STAYS);
                      setSubCategory([]);
                      await secureStorage.setItem(SS_ACTIVE_TAB, STAYS);
                    }}
                    style={{
                      ...mainCategoryStyles,
                      background: activeTab === STAYS ? "#101010" : "#FFF",
                    }}
                  >
                    <StayTabIcon
                      fill={activeTab === STAYS ? "#fff" : "#525252"}
                      style={{
                        marginRight: 10,
                        width: 18,
                        height: 18,
                        marginBottom: 3,
                      }}
                    />
                    <p
                      style={{
                        color: activeTab === STAYS ? "#fff" : "#525252",
                        margin: 0,
                        fontSize: 15,
                      }}
                    >
                      Stays
                    </p>
                  </div>
                  <div
                    onClick={async () => {
                      setExperienceTabFilter();
                    }}
                    style={{
                      ...mainCategoryStyles,
                      background:
                        activeTab === EXPERIENCES ? "#101010" : "#FFF",
                    }}
                  >
                    <ExperienceTabIcon
                      fill={activeTab === EXPERIENCES ? "#fff" : "#525252"}
                      style={{
                        marginRight: 10,
                        width: 18,
                        height: 18,
                        marginBottom: 3,
                      }}
                    />
                    <p
                      style={{
                        color: activeTab === EXPERIENCES ? "#fff" : "#525252",
                        margin: 0,
                        fontSize: 15,
                      }}
                    >
                      Experiences
                    </p>
                  </div>

                  {/* <div
                    onClick={async () => {
                      setActiveTab(EVENTS);
                      await secureStorage.setItem(SS_ACTIVE_TAB, EVENTS);
                    }}
                    style={{
                      ...mainCategoryStyles,
                      background: activeTab === EVENTS ? "#101010" : "#FFF",
                    }}
                  >
                    <EventTabIcon
                      fill={activeTab === EVENTS ? "#fff" : "#525252"}
                      style={{
                        marginRight: 10,
                        width: 18,
                        height: 18,
                        marginBottom: 3,
                      }}
                    />
                    <p
                      style={{
                        color: activeTab === EVENTS ? "#fff" : "#525252",
                        margin: 0,
                        fontSize: 15,
                      }}
                    >
                      Events
                    </p>
                  </div>
                  <div
                    onClick={async () => {
                      setActiveTab(TOURS);
                      await secureStorage.setItem(SS_ACTIVE_TAB, TOURS);
                    }}
                    style={{
                      ...mainCategoryStyles,
                      background: activeTab === TOURS ? "#101010" : "#FFF",
                    }}
                  >
                    <TourTabIcon
                      fill={activeTab === TOURS ? "#fff" : "#525252"}
                      style={{
                        marginRight: 10,
                        width: 18,
                        height: 18,
                        marginBottom: 3,
                      }}
                    />
                    <p
                      style={{
                        color: activeTab === TOURS ? "#fff" : "#525252",
                        margin: 0,
                        fontSize: 15,
                      }}
                    >
                      Tours
                    </p>
                  </div> */}
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 15,
                  }}
                >
                  
                </div> */}
              </div>

              {subCategory.length > 0 && (
                <div>
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: "600",
                      color: "#000",
                      margin: "10px 0px",
                    }}
                  >
                    Sub categories
                  </p>

                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "table",
                      clear: "both",
                      width: "100%",
                    }}
                  >
                    {subCategory.map((item) => (
                      <SubCategory title={item.name} value={item.value} />
                    ))}
                  </div>
                </div>
              )}

              <p
                style={{
                  fontSize: 18,
                  fontWeight: "600",
                  color: "#000",
                  marginTop: 20,
                }}
              >
                Price Range
              </p>

              <div
                style={{
                  width: "100%",
                }}
              >
                <p
                  style={{
                    margin: "5px 0px",
                    fontSize: 14,
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  ₹{priceRange}
                </p>
                <StylesProvider injectFirst>
                  <Slider
                    min={0}
                    max={MAX_PRICE_RANGE}
                    step={500}
                    color="#101010"
                    getAriaLabel={() => "Price Range"}
                    value={priceRange}
                    onChange={rangeSelector}
                    aria-labelledby="non-linear-slider"
                  />
                </StylesProvider>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      margin: "5px 0px",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    ₹0
                  </p>
                  <p
                    style={{
                      margin: "5px 0px",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    ₹{MAX_PRICE_RANGE}
                  </p>
                </div>

                <CustomButton
                  onClick={onApplyFilter}
                  styles={{ marginTop: 20, borderRadius: 5, width: "100%" }}
                  textStyles={{ fontWeight: "500", fontSize: 16 }}
                  title="Apply filters"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div
        style={{
          backgroundColor: WHITE_COLOR,
          borderRadius: "20px",
          color: "black",
          boxSizing: "border-box",
          display: "flex",
          padding: width > WIDTH_LIMIT ? "0px 25px " : "0px 5px",
          marginTop: -15,
          flexDirection: "column",
          minHeight: height * 0.8,
          width: width < WIDTH_LIMIT ? "100%" : "72%",
        }}
      >
        <>
          {width > WIDTH_LIMIT && (
            <p
              style={{
                fontWeight: "500",
                color: "#525252",
                fontSize: 20,
                padding: width > WIDTH_LIMIT ? "30px 15px 20px 15px" : "0px",
              }}
            >
              {data.length} {showActiveType} near {searchLocation}
            </p>
          )}

          {width < WIDTH_LIMIT ? (
            <div
              style={{
                position: "sticky",
                top: 80,
                background: "white",
                zIndex: 50,
                width: "100%",
              }}
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    marginTop: 20,
                    border: "1px solid #aaa",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <div
                    onClick={() => {
                      setOpenFilter((prev) => !prev);
                    }}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "83%" }}>
                      <p style={{ margin: 0 }}>{destination}</p>
                    </div>
                    <div>
                      <img
                        onClick={() => {
                          console.log("ahdsgajhd");
                        }}
                        style={{ width: 20, cursor: "pointer" }}
                        src={Edit}
                      />
                      <p style={{ margin: 0, marginTop: -5, fontSize: 13 }}>
                        Edit
                      </p>
                    </div>
                  </div>
                </div>

                {openFilter && (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      background: "#fff",
                      padding: "0px 0px 20px 0px",
                      borderRadius: 5,
                      boxShadow: "10px 10px 10px 10px #888888",
                      top: 0,
                    }}
                  >
                    <div style={{ padding: 15 }}>
                      <div>
                        <p
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                            color: "#000",
                            margin: "0px 0px 10px 0px",
                          }}
                        >
                          Search your location
                        </p>

                        <IconButton
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                          }}
                          onClick={() => setOpenFilter(false)}
                        >
                          <CloseIcon
                            style={{ color: "#101010" }}
                            fontSize="medium"
                          />
                        </IconButton>
                      </div>
                      <div style={{ position: "relative" }}>
                        <CustomInput
                          type="input"
                          placeholder="Search by City, State"
                          onChange={(val) => {
                            setDestination(val);
                            setShowLoactionSuggestion(true);
                            getLocationSuggestions(val);
                          }}
                          value={destination}
                          inputcomponentStyles={{
                            width: "87%",
                            fontWeight: destination ? "500" : "100",
                          }}
                          inputStyles={{
                            boxShadow: "none",
                            height: "40px",
                            backgroundColor: "white",
                            border: "1px solid #101010",
                          }}
                          isRightIconRequired={true}
                          rightIcon={
                            <img
                              style={{ width: 15, cursor: "pointer" }}
                              src={LocationIcon}
                            />
                          }
                        />

                        {showLoactionSuggestion && (
                          <div
                            style={{
                              position: "absolute",
                              top: 50,
                              width: "100%",
                              borderRadius: 5,
                              backgroundColor: "#fff",
                              maxHeight: 250,
                              overflow: "scroll",
                              zIndex: 10,
                              border: "1px solid #aaa",
                            }}
                          >
                            {locationArr.map((item, index) => (
                              <p
                                key={index}
                                onClick={() => {
                                  setDestination(item.value);
                                  setShowLoactionSuggestion(false);
                                }}
                                style={{
                                  borderBottom: "1px solid #ccc",
                                  margin: 5,
                                  padding: 10,
                                  fontSize: 16,
                                }}
                              >
                                {item.name}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <p
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                            color: "#000",
                            margin: "10px 0px",
                          }}
                        >
                          Main categories
                        </p>

                        <div
                          style={{
                            // display: "table",
                            display: "flex",
                            justifyContent: "space-between",
                            clear: "both",
                          }}
                        >
                          <div
                            onClick={async () => {
                              setActiveTab(STAYS);
                              setSubCategory([]);
                              await secureStorage.setItem(SS_ACTIVE_TAB, STAYS);
                            }}
                            style={{
                              ...mainCategoryStyles,
                              background:
                                activeTab === STAYS ? "#101010" : "#FFF",
                            }}
                          >
                            <StayTabIcon
                              fill={activeTab === STAYS ? "#fff" : "#525252"}
                              style={{
                                marginRight: 10,
                                width: 18,
                                height: 18,
                                marginBottom: 3,
                              }}
                            />
                            <p
                              style={{
                                color: activeTab === STAYS ? "#fff" : "#525252",
                                margin: 0,
                                fontSize: 15,
                              }}
                            >
                              Stays
                            </p>
                          </div>
                          <div
                            onClick={() => {
                              setExperienceTabFilter();
                            }}
                            style={{
                              ...mainCategoryStyles,
                              background:
                                activeTab === EXPERIENCES ? "#101010" : "#FFF",
                            }}
                          >
                            <ExperienceTabIcon
                              fill={
                                activeTab === EXPERIENCES ? "#fff" : "#525252"
                              }
                              style={{
                                marginRight: 10,
                                width: 18,
                                height: 18,
                                marginBottom: 3,
                              }}
                            />
                            <p
                              style={{
                                color:
                                  activeTab === EXPERIENCES
                                    ? "#fff"
                                    : "#525252",
                                margin: 0,
                                fontSize: 15,
                              }}
                            >
                              Experiences
                            </p>
                          </div>
                        </div>
                      </div>

                      {subCategory.length > 0 && (
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                              color: "#000",
                              margin: "10px 0px",
                            }}
                          >
                            Sub categories
                          </p>

                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "table",
                              clear: "both",
                              width: "100%",
                            }}
                          >
                            {subCategory.map((item) => (
                              <SubCategory
                                title={item.name}
                                value={item.value}
                              />
                            ))}
                          </div>
                        </div>
                      )}

                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "600",
                          color: "#000",
                          marginTop: 10,
                        }}
                      >
                        Price Range
                      </p>

                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            margin: "5px 0px",
                            fontSize: 14,
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          ₹{priceRange}
                        </p>
                        <StylesProvider injectFirst>
                          <Slider
                            min={0}
                            max={MAX_PRICE_RANGE}
                            step={500}
                            color="#101010"
                            getAriaLabel={() => "Price Range"}
                            value={priceRange}
                            onChange={rangeSelector}
                            aria-labelledby="non-linear-slider"
                          />
                        </StylesProvider>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              margin: "5px 0px",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            ₹0
                          </p>
                          <p
                            style={{
                              margin: "5px 0px",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            ₹{MAX_PRICE_RANGE}
                          </p>
                        </div>

                        <CustomButton
                          onClick={onApplyFilter}
                          styles={{
                            marginTop: 20,
                            borderRadius: 5,
                            width: "100%",
                          }}
                          textStyles={{ fontWeight: "500", fontSize: 16 }}
                          title="Apply filters"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {width < WIDTH_LIMIT && (
            <p
              style={{
                fontWeight: "500",
                color: "#525252",
                fontSize: 15,
                margin: 0,
                marginTop: 20,
                padding: width > WIDTH_LIMIT ? "0px " : "0px 10px",
              }}
            >
              {data.length} {showActiveType} near {searchLocation}
            </p>
          )}

          {loading ? (
            <>
              {width < WIDTH_LIMIT ? (
                <div
                  style={{
                    padding: width > WIDTH_LIMIT ? "0px " : "0px 10px",
                  }}
                >
                  <div style={{ marginTop: 20 }}>
                    <Shimmer
                      style={{ marginTop: 10 }}
                      className="image-shimmer"
                      width={"100%"}
                      height={250}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Shimmer
                      style={{ marginTop: 10 }}
                      className="image-shimmer"
                      width={"100%"}
                      height={250}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Shimmer
                      style={{ marginTop: 10 }}
                      className="image-shimmer"
                      width={"100%"}
                      height={250}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      padding: width > WIDTH_LIMIT ? "0px 15px" : "0px",
                    }}
                  >
                    <Shimmer
                      className="image-shimmer"
                      width={"30%"}
                      height={300}
                    />
                    <Shimmer
                      style={{ marginLeft: 10 }}
                      className="image-shimmer"
                      width={"67%"}
                      height={300}
                    />
                  </div>
                  <div style={{ display: "flex", marginTop: 20 }}>
                    <Shimmer
                      className="image-shimmer"
                      width={"30%"}
                      height={300}
                    />
                    <Shimmer
                      style={{ marginLeft: 10 }}
                      className="image-shimmer"
                      width={"67%"}
                      height={300}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {data?.length !== 0 ? (
                <div
                  style={{
                    padding: width > WIDTH_LIMIT ? "0px 15px" : "0px 10px",
                  }}
                >
                  {data.map((item, index) => (
                    <PropertyItem
                      activeTab={activeTab}
                      activeSubCategory={activeSubCategory}
                      index={index}
                      item={item}
                      setEnquiryOpen={() => {
                        setActiveEnquiryProperty(item);
                        setEnquiryOpen(true);
                      }}
                    />
                  ))}
                </div>
              ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      padding: width > WIDTH_LIMIT ? "0px 15px" : "0px 10px",
                    }}
                  >
                    <p
                      style={{
                        color: "black",
                        marginTop: height * 0.3,
                      }}
                    >
                      No Properties Avaliable, for Provided Filter
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </>
      </div>

      {enquiryOpen && (
        <Enquiry
          open={enquiryOpen}
          setEnquiryOpen={setEnquiryOpen}
          activeEnquiryProperty={activeEnquiryProperty}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    destinationRed: state.SearchReducer.destination,
    checkInCheckOutRed: state.SearchReducer.checkInCheckOut,
    roomGuestRed: state.SearchReducer.roomGuest,
    propertyTypeRed: state.SearchReducer.propertyType,
  };
};
export default connect(mapStateToProps)(PropretyList);
